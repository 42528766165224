import React, {useEffect, useContext, useState, useRef} from 'react'
import classes from "./Vacancies.module.scss"
import logo from "./../../image/logo.png"
import x from "./../../image/x.png"
import styled, { css } from 'styled-components'
import { StickyContainer, Sticky } from 'react-sticky';
import {Link} from "react-router-dom";
import clsx from "clsx";
import { useHistory } from "react-router";
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext';

function Vacancies(props) {
    const controls = useRef();
    const {setType} = useContext(CustomCursorContext)
    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div className={classes.loaderContainer}>
            <div className={classes.line}></div>
                <div
                    className={clsx(classes.ProjectContainerProp, "animate__animated", "animate__fadeInUpBig", "animate__slow")}
                    onMouseEnter={() => setType('default')}
                >
                    <div className={classes.vacancies}>
                        <div className={classes.propositions}>
                            <div className={classes.propositionsName}>
                                <h3>Что мы 
                                предлагаем?</h3>
                            </div>
                            <div className={classes.propositionInfoFlex}>
                                <div className={classes.propositionInfo}>
                                    <div className={classes.propositionsText}>
                                    <ul className={classes.propList}>
                                        <li className={classes.listItem}>Достойный уровень заработной платы</li>
                                        <li className={classes.listItem}>Полностью оплачиваемая стажировка и обучение за счет компании</li>
                                        <li className={classes.listItem}>Возможность быстрого карьерного роста (от 3х месяцев)</li>
                                        <li className={classes.listItem}>Молодой, дружный коллектив и насыщенная корпоративная жизнь</li>
                                        <li className={classes.listItem}>Комфортный и современный офис в центре города</li>
                                        <li className={classes.listItem}>График 5/2 с 10:00 до 19:00</li>
                                        <li className={classes.listItem}>Крутое, лояльное руководство</li>
                                    </ul>
                                </div>
                                    <div className={classes.propositionsText}>
                                        <h4>Опыт — это важный, но не первый критерий выбора кандидата на должность. Если вы действительно проявите неподдельный интерес к работе у нас, 
                                    захотите научиться новым скилам и постоянно повышать свои навыки, то мы вас научим! Амбиции, страсть к своему делу, стремление к профессиональному росту и достижению результатов, креативность, высокая оплата труда и уважение к каждому сотруднику — это все про нашу команду.</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.vacanciesBlock}>
                            <div className={classes.vacanciesMain}>
                                <div className={classes.vacanBlc}>
                                    <h3>Вакансии</h3>
                                    <div className={classes.respond}>
                                        <Link
                                            disableHeader={props.disableHeader}
                                            style={{ color: "white", textDecoration: "none" }}
                                            to={"/GoVacan"}
                                            onMouseEnter={() => setType('activeResp')}
                                            onMouseLeave={() => setType('defaultResp')}
                                        >
                                            Откликнуться
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g id="24 / arrows / arrow-right">
                                                    <path id="icon" fillRule="evenodd" clipRule="evenodd"
                                                        d="M17.5858 13H3V11H17.5858L11.2929 4.70706L12.7071 3.29285L21.4142 12L12.7071 20.7071L11.2929 19.2928L17.5858 13Z"
                                                        fill="white"/>
                                                </g>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.vacancionPosts}>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Маркетолог</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Входящая воронка (показатели, гипотезы и реализация)</li>
                                                <li className={classes.listItem}>Управление трафиком (подрядчики, календарь запуска гипотез, овые каналы)</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Моушн–дизайнер</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Создание небольших тизеров для новостной рассылки (работа с готовыми шаблонами)</li>
                                                <li className={classes.listItem}>Разработка моушн-заставок к видео роликам</li>
                                                <li className={classes.listItem}>Разработка анимированных баннеров и сторис.</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Специалист по чат-ботам, архитектор воронок, сисадмин</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Работать с email-сервисами и сопутствующими сервисами</li>
                                                <li className={classes.listItem}>Разрабатывать воронки запуски курсов</li>
                                                <li className={classes.listItem}>Заниматься рассылкой в мессенджерах</li>
                                                <li className={classes.listItem}>Создавать чат-боты</li>
                                                <li className={classes.listItem}>Работать с сервисами для рассылок в мессенджерах (senler, bothelp, smartsender, leeloo, manychat - любые на выбор)</li>
                                                <li className={classes.listItem}>егментация и работа с базой</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div className={classes.vacancionDescription}>
                                        <h2>HR-менеджер</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Размещать вакансии в различных источниках</li>
                                                <li className={classes.listItem}>Поиск, точечный подбор и анализ резюме кандидатов</li>
                                                <li className={classes.listItem}>Проводить первичное телефонное интервью с кандидатами</li>
                                                <li className={classes.listItem}>Проводить отборочное собеседование с кандидатами</li>
                                                <li className={classes.listItem}>Проводить оценку персонала во время и после испытательного срока</li>
                                                <li className={classes.listItem}>Кайфовать от своей работы и постоянно развиваться!</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Копирайтер</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Анализировать целевую аудиторию</li>
                                                <li className={classes.listItem}>Писать «вкусные» продающие или информационные тексты на тему инвестиций</li>
                                                <li className={classes.listItem}>Определять стилистику подачи материалов</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Frontend-разработчик на React</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Разработка сайта на React</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Контент–менеджер</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Постинг готовой информации в телеграм-канал</li>
                                                <li className={classes.listItem}>Ведение двух аккаунтов экспертов онлайн-школы</li>
                                                <li className={classes.listItem}>Помощь в составлении основной ленты для Instagram экспертов</li>
                                                <li className={classes.listItem}>Составление контент-плана для ленты-сториз и ее ежедневное ведение</li>
                                                <li className={classes.listItem}>Актуализация информации в хайлатсах Instagram</li>
                                                <li className={classes.listItem}>Отслеживание появления новых форматов в Instagram и их внедрение в аккаунты</li>
                                                <li className={classes.listItem}>Создание и размещение контента на сайте</li>
                                                <li className={classes.listItem}>Съемка коротких видео для сторис</li>
                                                <li className={classes.listItem}>Взаимодействие с экспертами для производства контента в соцсетях</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Менеджер проекта</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Создание, упаковка, запуск новых продуктов</li>
                                                <li className={classes.listItem}>Стратегическое планирование запуска новых проектов (онлайн-курсов) и роста текущих</li>
                                                <li className={classes.listItem}>Тактическое управление проектами</li>
                                                <li className={classes.listItem}>Аналитика и разработка новых направлений онлайн-курсов, поиск новых ниш на рынке</li>
                                                <li className={classes.listItem}>Упаковка смыслов и реализация воронки продаж для проектов</li>
                                                <li className={classes.listItem}>Анализ ниши, рынка, конкурентов и целевой аудитории</li>
                                                <li className={classes.listItem}>Регулярная аналитика конкурентов</li>
                                                <li className={classes.listItem}>Проработка концепций курсов</li>
                                                <li className={classes.listItem}>Построение mvp-моделей новых запусков, аналитика итогов тестирования</li>
                                                <li className={classes.listItem}>Разработка системы kpi запусков</li>
                                                <li className={classes.listItem}>Маркетинговая упаковка курсов</li>
                                                <li className={classes.listItem}>Построение логики воронок/чат-ботов</li>
                                                <li className={classes.listItem}>Написание продающих текстов для посадочных и продающих страниц</li>
                                                <li className={classes.listItem}>Работа с продакшн-командой</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Таргетолог</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Настройка таргетированной рекламы в facebook, vk или tiktok (любой канал)</li>
                                                <li className={classes.listItem}>Аналитика рекламных компаний</li>
                                                <li className={classes.listItem}>Аналитика конкурентов и поиск интересных идеи для тестирования</li>
                                                <li className={classes.listItem}>Работа с бюджетами от 30 000</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
                <div
                    className={clsx(classes.ProjectContainerPropMobile, "animate__animated", "animate__fadeInUp")}
                    onMouseEnter={() => setType('default')}
                >
                    <div className={classes.vacancies}>
                        <div className={classes.propositions}>
                            <div className={classes.propositionsName}>
                                <h3>Что мы 
                                предлагаем?</h3>
                            </div>
                            <div className={classes.propositionInfoFlex}>
                                <div className={classes.propositionInfo}>
                                    <div className={classes.propositionsText}>
                                    <ul className={classes.propList}>
                                        <li className={classes.listItem}>Достойный уровень заработной платы</li>
                                        <li className={classes.listItem}>Полностью оплачиваемая стажировка и обучение за счет компании</li>
                                        <li className={classes.listItem}>Возможность быстрого карьерного роста (от 3х месяцев)</li>
                                        <li className={classes.listItem}>Молодой, дружный коллектив и насыщенная корпоративная жизнь</li>
                                        <li className={classes.listItem}>Комфортный и современный офис в центре города</li>
                                        <li className={classes.listItem}>График 5/2 с 10:00 до 19:00</li>
                                        <li className={classes.listItem}>Крутое, лояльное руководство</li>
                                    </ul>
                                </div>
                                    <div className={classes.propositionsText}>
                                        <h4>Опыт — это важный, но не первый критерий выбора кандидата на должность. Если вы действительно проявите неподдельный интерес к работе у нас, 
                                    захотите научиться новым скилам и постоянно повышать свои навыки, то мы вас научим! Амбиции, страсть к своему делу, стремление к профессиональному росту и достижению результатов, креативность, высокая оплата труда и уважение к каждому сотруднику — это все про нашу команду.</h4>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.vacanciesBlock}>
                            <div className={classes.vacanciesMain}>
                                <div className={classes.vacanBlc}>
                                    <h3>Вакансии</h3>
                                    <div className={classes.respond}>
                                        <Link
                                            style={{ color: "white", textDecoration: "none" }}
                                            to={"/GoVacan"}
                                            onMouseEnter={() => setType('activeResp')}
                                            onMouseLeave={() => setType('defaultResp')}
                                        >
                                            Откликнуться
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g id="24 / arrows / arrow-right">
                                                    <path id="icon" fillRule="evenodd" clipRule="evenodd"
                                                        d="M17.5858 13H3V11H17.5858L11.2929 4.70706L12.7071 3.29285L21.4142 12L12.7071 20.7071L11.2929 19.2928L17.5858 13Z"
                                                        fill="white"/>
                                                </g>
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.vacancionPosts}>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Маркетолог</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Входящая воронка (показатели, гипотезы и реализация)</li>
                                                <li className={classes.listItem}>Управление трафиком (подрядчики, календарь запуска гипотез, овые каналы)</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Моушн–дизайнер</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Создание небольших тизеров для новостной рассылки (работа с готовыми шаблонами)</li>
                                                <li className={classes.listItem}>Разработка моушн-заставок к видео роликам</li>
                                                <li className={classes.listItem}>Разработка анимированных баннеров и сторис.</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Специалист по чат-ботам, архитектор воронок, сисадмин</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Работать с email-сервисами и сопутствующими сервисами</li>
                                                <li className={classes.listItem}>Разрабатывать воронки запуски курсов</li>
                                                <li className={classes.listItem}>Заниматься рассылкой в мессенджерах</li>
                                                <li className={classes.listItem}>Создавать чат-боты</li>
                                                <li className={classes.listItem}>Работать с сервисами для рассылок в мессенджерах (senler, bothelp, smartsender, leeloo, manychat - любые на выбор)</li>
                                                <li className={classes.listItem}>егментация и работа с базой</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div className={classes.vacancionDescription}>
                                        <h2>HR-менеджер</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Размещать вакансии в различных источниках</li>
                                                <li className={classes.listItem}>Поиск, точечный подбор и анализ резюме кандидатов</li>
                                                <li className={classes.listItem}>Проводить первичное телефонное интервью с кандидатами</li>
                                                <li className={classes.listItem}>Проводить отборочное собеседование с кандидатами</li>
                                                <li className={classes.listItem}>Проводить оценку персонала во время и после испытательного срока</li>
                                                <li className={classes.listItem}>Кайфовать от своей работы и постоянно развиваться!</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Копирайтер</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Анализировать целевую аудиторию</li>
                                                <li className={classes.listItem}>Писать «вкусные» продающие или информационные тексты на тему инвестиций</li>
                                                <li className={classes.listItem}>Определять стилистику подачи материалов</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Frontend-разработчик на React</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Разработка сайта на React</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Контент–менеджер</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Постинг готовой информации в телеграм-канал</li>
                                                <li className={classes.listItem}>Ведение двух аккаунтов экспертов онлайн-школы</li>
                                                <li className={classes.listItem}>Помощь в составлении основной ленты для Instagram экспертов</li>
                                                <li className={classes.listItem}>Составление контент-плана для ленты-сториз и ее ежедневное ведение</li>
                                                <li className={classes.listItem}>Актуализация информации в хайлатсах Instagram</li>
                                                <li className={classes.listItem}>Отслеживание появления новых форматов в Instagram и их внедрение в аккаунты</li>
                                                <li className={classes.listItem}>Создание и размещение контента на сайте</li>
                                                <li className={classes.listItem}>Съемка коротких видео для сторис</li>
                                                <li className={classes.listItem}>Взаимодействие с экспертами для производства контента в соцсетях</li>
                                            </ul>
                                        </span>
                                    </div>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Менеджер проекта</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Создание, упаковка, запуск новых продуктов</li>
                                                <li className={classes.listItem}>Стратегическое планирование запуска новых проектов (онлайн-курсов) и роста текущих</li>
                                                <li className={classes.listItem}>Тактическое управление проектами</li>
                                                <li className={classes.listItem}>Аналитика и разработка новых направлений онлайн-курсов, поиск новых ниш на рынке</li>
                                                <li className={classes.listItem}>Упаковка смыслов и реализация воронки продаж для проектов</li>
                                                <li className={classes.listItem}>Анализ ниши, рынка, конкурентов и целевой аудитории</li>
                                                <li className={classes.listItem}>Регулярная аналитика конкурентов</li>
                                                <li className={classes.listItem}>Проработка концепций курсов</li>
                                                <li className={classes.listItem}>Построение mvp-моделей новых запусков, аналитика итогов тестирования</li>
                                                <li className={classes.listItem}>Разработка системы kpi запусков</li>
                                                <li className={classes.listItem}>Маркетинговая упаковка курсов</li>
                                                <li className={classes.listItem}>Построение логики воронок/чат-ботов</li>
                                                <li className={classes.listItem}>Написание продающих текстов для посадочных и продающих страниц</li>
                                                <li className={classes.listItem}>Работа с продакшн-командой</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                                <div className={classes.vacancionDescriptionFlexs}>
                                    <div className={classes.vacancionDescription}>
                                        <h2>Таргетолог</h2>
                                        <span>
                                            <ul className={classes.propList}>
                                                <li className={classes.listItem}>Настройка таргетированной рекламы в facebook, vk или tiktok (любой канал)</li>
                                                <li className={classes.listItem}>Аналитика рекламных компаний</li>
                                                <li className={classes.listItem}>Аналитика конкурентов и поиск интересных идеи для тестирования</li>
                                                <li className={classes.listItem}>Работа с бюджетами от 30 000</li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
    )
}


export default Vacancies