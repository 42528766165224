import React, {useContext, useRef, useEffect} from 'react'
import  classes from "./main.module.scss"
import {Header} from "../components/Header/header";
import {FirstSlide} from "../components/FirstSlideContent/FirstSlideContent";
import {SecondSlide} from "../components/SecondSlide/SecondSl";
import { ThirdSlide } from "../components/ThirdSlide/ThirdSlide";
import clsx from "clsx";

export function Main(props) {

    let hasRendered = useRef(false);
    useEffect(() => {
        hasRendered.current = true;
    }, [])

 return (
     <div
         className={hasRendered.current 
            ? clsx(classes.Main, "animate__animated", "animate__fadeIn")
            : clsx(classes.Main, "animate__animated", "animate__fadeIn", "animate__delay-1s")}
        
     >
         
         <div className={classes.FirstSlide}>
             <FirstSlide />
         </div>
         <SecondSlide setScrollPosition={props.setScrollPosition} />
     </div>
 )
}