import React, {useContext, useEffect} from "react"
import classes from "./style.module.scss"
import clsx from "clsx";
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext';


export function Skills() {
    const {setType} = useContext(CustomCursorContext)
    useEffect(()=>{
        window.scrollTo(0,0)},[])
    return (
        <div className={classes.loaderContainer}>
                <div className={classes.line}></div>
            <div
                className={clsx(classes.ProjectContainerSkills, "animate__animated", "animate__fadeInUpBig")}
                onMouseEnter={() => setType('default')}
            >
                {/*<img alt="img" className={classes.logoMenu} src={logo}/>*/}
                {/*<Link className={classes.btnClose} to={"/"}>*/}
                {/*    <img alt="img" src={x}/>*/}
                {/*</Link>*/}
                <div className={classes.header}>Карта скиллов</div>
                <div className={classes.rowSm}>
                        <div className={classes.cardSkills}>
                        <div className={classes.headerContainer}>
                            <div className={classes.headerCard}>
                                Консалтинг,<br/>
                                стратегия, <br/>
                                упаковка бизнеса

                            </div>
                        </div>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>Проводим аудит бизнес-концепции</li>
                            <li className={classes.listItem}>Внедряем эффективные коммуникации внутри проекта</li>
                            <li className={classes.listItem}>Модернизируем процессы и упаковку продукта</li>
                            <li className={classes.listItem}>Создаем постоянный трафик</li>
                            <li className={classes.listItem}>Отлаживаем статистический сбор и учет данных на каждом бизнес-этапе</li>
                            <li className={classes.listItem}>Увеличиваем первичные продажи за счет проверенных методик</li>
                            <li className={classes.listItem}>Настраиваем и контролируем процессы апсейл и кросссейл</li>
                            <li className={classes.listItem}>Обучаем и мотивируем сотрудников</li>
                            <li className={classes.listItem}>Сопровождаем на каждом этапе работы</li>
                        </ul>
                        </div>
                        <div className={classes.cardSkills}>
                        <div className={classes.headerContainer}>
                            <div className={classes.headerCard}>

                                Маркетинг <br />
                                и продвижение
                            </div>
                        </div>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>Разрабатываем индивидуальную стратегию продвижения</li>
                            <li className={classes.listItem}>Анализируем целевую аудиторию</li>
                            <li className={classes.listItem}>Занимаемся разработкой и дизайном сайтов, приложений любой сложности</li>
                            <li className={classes.listItem}>Управляем репутацией бизнеса</li>
                            <li className={classes.listItem}>Используем современные методы пиара</li>
                            <li className={classes.listItem}>Учим пользоваться маркетинговыми инструментами</li>
                            <li className={classes.listItem}>Повышаем лояльность аудитории</li>
                            <li className={classes.listItem}>Автоматизируем продажи и коммуникацию с клиентами</li>
                        </ul>
                    </div>
                        <div className={classes.cardSkills}>
                        <div className={classes.headerContainer}>
                            <div className={classes.headerCard}>
                                Продажи
                            </div>
                        </div>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>Строим системы продаж и взаимодействия с клиентом</li>
                            <li className={classes.listItem}>Внедряем CRM системы</li>
                            <li className={classes.listItem}>Подбираем продавцов и адаптируем их внутри компании</li>
                            <li className={classes.listItem}>Повышаем компетенции и занимаемся коучингом</li>
                            <li className={classes.listItem}>Строим KPI и мотивируем на результат</li>
                        </ul>
                        </div>
                        <div className={classes.cardSkills}>
                        <div className={classes.headerContainer}>
                            <div className={classes.headerCard}>
                                Брендинг < br />
                                и фирменный < br />
                                стиль

                            </div>
                        </div>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>Работаем над неймингом</li>
                            <li className={classes.listItem}>Разрабатываем позиционирование и имидж компании</li>
                            <li className={classes.listItem}>Создаем логотип и фирменный стиль</li>
                            <li className={classes.listItem}>Разрабатываем POS-материалы</li>
                            <li className={classes.listItem}>Создаем корпоративные и рекламные видео</li>
                            <li className={classes.listItem}>Разрабатываем промышленный дизайн</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}