import React, {useEffect, useContext} from "react";
import classes from "./style.module.scss"
import clsx from "clsx";
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext';

export function Footer() {
    const {setType} = useContext(CustomCursorContext)

    return (
        <div className={classes.footerGlobalContainer}  >
            <div className={classes.footerWrap}>
                <div className={classes.footerContainer}>
                    <div onClick={()=>window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    })}
                        className={classes.firstCol}
                        onMouseEnter={() => setType('active')}
                                onMouseLeave={() => setType('default')}
                    >
                        <svg width="16" height="41" viewBox="0 0 16 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.70711 0.292892C8.31658 -0.0976295 7.68342 -0.0976296 7.2929 0.292892L0.928934 6.65685C0.538409 7.04738 0.538409 7.68054 0.928934 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41422L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292892ZM9 41L9 1L7 1L7 41L9 41Z"
                                fill="black"/>
                        </svg>
                        <span>ВВЕРХ</span>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5" cy="5" r="5" fill="black"/>
                        </svg>
                    </div>
                    <div className={clsx(classes.ColInfo, classes.secondColInfo)}>
                        <div className={classes.rowFirst}>
                            г. Ярославль, ул. Флотская, 18
                        </div>
                        <div className={classes.rowSecond}>
                            C 9:00 до 18:00
                        </div>
                    </div>
                    <div className={clsx(classes.ColInfo, classes.secondColInfo)}>
                        <div className={classes.rowFirst}>
                            <a
                                onMouseEnter={() => setType('active')}
                                onMouseLeave={() => setType('default')}
                                href="https://t.me/Ceo_agency">Telegram</a>
                        </div>

                    </div>
                    <div className={classes.lastCol}>
                        <div>
                            <a
                                onMouseEnter={() => setType('active')}
                                onMouseLeave={() => setType('default')}
                                href=" mailto: ceoagency@gmail.com">ceoagency@gmail.com</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.footerWrap2}>
                <div className={classes.footerContainer}>
                    <div className={clsx(classes.ColInfo, classes.secondColInfo)}>
                        <div className={classes.rowFirst}>
                            г. Ярославль, ул. Флотская, 18
                        </div>
                        <div className={classes.rowSecond}>
                            C 9:00 до 18:00
                        </div>
                    </div>
                    <div className={clsx(classes.ColInfo, classes.secondColInfo)}>
                        <div className={classes.rowFirst}>
                        <a
                            href="https://t.me/Ceo_agency"
                            onMouseEnter={() => setType('active')}
                            onMouseLeave={() => setType('default')}
                        >Telegram</a>
                        </div>

                    </div>

                </div>
                <div className={classes.lastCol}>
                    <div>
                    <a
                        href="mailto: ceoagency@gmail.com"
                        onMouseEnter={() => setType('active')}
                        onMouseLeave={() => setType('default')}
                    >ceoagency@gmail.com</a>
                    </div>
                </div>
            </div>
            <div className={classes.LastBlock}>
                <div className={classes.footerContainerLast}>
                    <div className={classes.firstInfoFooter}>
                        © 2012 — 2021 / CEO Agency
                    </div>
                    <div className={classes.secondInfoFooter}>
                        <a
                            href="#"
                            onMouseEnter={() => setType('active')}
                            onMouseLeave={() => setType('default')}
                        >Политика конфиденциальности</a>
                    </div>
                    <div className={classes.thirdInfoFooter}>
                        <span><a
                            onMouseEnter={() => setType('active')}
                                onMouseLeave={() => setType('default')}
                            href="#">Правила обработки персональных данных</a></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
// function ScrollToTop() {
//
//     useEffect(() => {
//         window.scrollTo(0, 0)
//     }, [])
//
//     return null
// }