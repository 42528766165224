import React from 'react';

export type CursorLookType = 'default' | 'active' | 'activeC' | 'defaultC' | 'activeResp' | 'defaultResp';


export type CustomCursorType = {
    type: CursorLookType,
    setType: (type: CursorLookType) => void;
}

const CustomCursorContext = React.createContext<CustomCursorType>({
    type: 'default',
    setType: () => {}
})

export default CustomCursorContext;