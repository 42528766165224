import logo from './logo.svg';
import './App.css';
import {BrowserRouter, HashRouter, Link, Redirect, useLocation} from "react-router-dom";
import {Route, Switch, useHistory} from "react-router";
import React, {lazy, useEffect, useState,Suspense} from "react";
import {Main} from "./Main/Main";
import {ThirdSlide} from "./components/ThirdSlide/ThirdSlide";
import {Footer} from "./components/Footer/Footer";
import {Menu} from "./components/Menu/Menu";
import {GoClient} from "./components/GoClient/client";
import Anime from "react-anime";
import {Skills} from "./components/Skills/Skills";
import {Header} from "./components/Header/header";
import {Loader} from "./components/Loader/Loader";
import Vacancies from './components/Vacancies/Vacancies';
import Govacan from './components/GoVacan/Govacan';
import {CustomCursor} from './components/CustomCursor/CustomCursor';
import { Fragment } from 'react';
import CustomCursorManager from './components/CustomCursor/context/manager';

export function App(props) {
    const [state, setState] = useState(false)
    const [animation,setAnimation] = useState(true)
    const [scrollPosition, setScrollPosition] = useState(0);
    const history = useHistory()
    history.listen((i)=>{
        if (i.hash !== "#/") {
            setState(true)
        } else {
            setState(false)
            // setTimeout(()=>window.scrollTo(0,scrollPosition),0)
        }
    })
    const handlerFunction = ()=> setScrollPosition(0)
    useEffect(() => {
        if (history.location.hash === "#/" || history.location.hash === "/") {
            setState(false)

        } else {
            setState(true)
        }
    }, [])
    useEffect(() => {
        // setTimeout(()=>window.scrollTo(0,0),15)
        setTimeout(()=>setAnimation(false),4000)
    })
    

 
    const LazyMain = lazy(() => import("./Main/PartPage"));


    const [headerOn, setHeaderOn] = React.useState(true)


    const disableHeader = () => {
        setHeaderOn(false)
        setTimeout(() => {
            setHeaderOn(true)
        }, 800)
    }

    useEffect(() => {
        disableHeader()
    }, [window.location.href])

    

    return (
        <HashRouter>
            <CustomCursorManager>
            <CustomCursor />
            <div className="App">
                {!animation ?
                    <>  
                        <Suspense fallback={<></>}>
                                    <Header
                                        headerOn={headerOn}
                                        state={state}
                                        setScrollPosition={handlerFunction}
                                    />
                                <Switch>
                                        <Route exact path={"/"} children={<LazyMain scrollPosition={scrollPosition} setScrollPosition={setScrollPosition} />}/>
                                        <Route path={"/Menu"} children={<Menu disableHeader={disableHeader}/>} />
                                        <Route path={"/GoClient"} children={<GoClient/>}/>
                                        <Route path={"/Skills"} children={<Skills />} />
                                        <Route path={"/Vacancies"} children={<Vacancies disableHeader={disableHeader}/>} />
                                        <Route path={"/GoVacan"} children={<Govacan />} />
                                        <Redirect to={"/"} />
                                </Switch>
                        </Suspense>
                    </>
                    :
                    <Loader />
                }
            </div>
            </CustomCursorManager>
        </HashRouter>

    );
}

