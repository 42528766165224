
import React, {useEffect, useState, useContext} from "react"
import classes from "./style.module.scss";
import logo from "../../image/logo.png";
import {Link} from "react-router-dom";
import x from "../../image/x.png";
import clsx from "clsx";
import {useHistory} from "react-router";
import axios from "axios";
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext';

function Govacan(props) {
    const {setType} = useContext(CustomCursorContext)
    useEffect(()=>{
        window.scrollTo(0,0)},[])
    const [error,setError] = useState(false)
    const [state, setState] = useState(false)
    const [inputs,setInputs] = useState(
        {
            name: '',
            number: '',
            vacan: '',
            portfolio: '',
            date: new Date().toString(),
        }
    )
    const changeHandler = (e) => {
        setInputs((prev)=>({...prev,[e.target.name] : e.target.value}))
    }

    const submitHandler =() => {
        if (inputs.name.length > 1 && inputs.number.length  > 9 && inputs.vacan.length > 3 && inputs.date.length > 5) {
            axios.post('https://sheet.best/api/sheets/7d8be360-38a3-47c8-997b-4942e9e6205a', inputs)
                .then(response => {
                    console.log(response);
                })
            setState(true)
        } else  {
            setError(true)
        }
    }
    return (
        <div className={classes.loaderContainer}>
                <div className={classes.line}></div>
        <div
            className={clsx(classes.ClientContaner, "animate__animated", "animate__fadeInUpBig")}
            onMouseEnter={() => setType('default')}
        >
            {/*<img alt="img" className={classes.logoMenu} src={logo}/>*/}
            {/*<Link className={classes.btnClose} to={"/"}>*/}
            {/*    <img alt="img"  src={x}/>*/}
            {/*</Link>*/}
            <div className={clsx(classes.leftContainer,state ? classes.activeLeftContainer : "")}>
                <div className={clsx(classes.headerLeftContainer,state ? classes.headerLeftContainer2 : "")}>
                    <div className={classes.headerClient}>
                        {!state ?
                            "Оставьте свое резюме" : "Отправлено!"
                        }
                    </div>
                    {!state &&
                    <div className={classes.aboutClient}>
                        <div>
                            Кратко расскажите о себе.
                        </div>
                        <div>
                            Заполните форму ниже 
                            или свяжитесь с нами 
                            любым удобным способом.
                        </div>
                    </div>
                    }
                </div>
                <div className={classes.footerLeft}>
                    <div className={classes.email}>
                        <a
                            href="https://t.me/Ceo_agency"
                            onMouseEnter={() => setType('activeResp')}
                        onMouseLeave={() => setType('defaultResp')}
                        >Telegram</a>
                    </div>
                    <div className={classes.footer}>
                        <span><a
                            href="mailto: ceoagency@gmail.com"
                            onMouseEnter={() => setType('activeResp')}
                        onMouseLeave={() => setType('defaultResp')}
                        >ceoagency@gmail.com</a></span>
                    </div>
                </div>
            </div>
            <div className={clsx(classes.rightContainer,state ? classes.rightContainer2 : "")}>
                {!state &&
                    <>
                    <div className={classes.containerUpper}>
                        <div className={classes.col}>
                            <div className={classes.HeaderInput}>Ваше имя</div>
                            <input value={inputs.name} onChange={changeHandler} className={classes.input2} name={"name"} type="text"/>
                        </div>
                        <div className={classes.col}>
                            <div className={classes.HeaderInput}>Номер телефона</div>
                            <input className={classes.input2} value={inputs.number} onChange={changeHandler} name={"number"} type="number" placeholder="+ 7"/>
                        </div>
                    </div>
                    <div className={classes.HeaderSecond} style={{margin: "12px 0 0 0"}}>На какой должности хотели бы работать?</div>
                    <div><textarea value={inputs.vacan} onChange={changeHandler} name={"vacan"} cols="20" rows="2" className={classes.input} type="text"/></div>
                    <div className={classes.HeaderSecond} style={{margin: "24px 0 0 0"}}>
                        Ссылка на портфолио
                    </div>
                    <div>
                        <textarea value={inputs.portfolio} onChange={changeHandler} name={"portfolio"} cols="20" rows="2" className={classes.input} type="text" /></div>
                    <div className={classes.containerFooter}>
                        <div className={classes.col}>
                            <div className={classes.afterInfo}>
                                <span>
                                    Нажимая «Отправить»,<br/>
                                    соглашаюсь с <a
                                        href="#"
                                        onMouseEnter={() => setType('activeResp')}
                                        onMouseLeave={() => setType('defaultResp')}
                                    >правилами</a> <br />
                                    обработки персональных данных
                                </span>
                            </div>
                        </div>
                        <div className={classes.col}>
                            <div onClick={submitHandler} className={classes.BtnSubmit}>
                                Отправить
                                {error && <div className={classes.Error}>Заполните все поля.</div>}
                            </div>
                        </div>
                    </div>
                </>
                }
                {state &&
                    <>
                        <div className={clsx("animate__animated", "animate__fadeInDown")}>
                            <div className={classes.textWelcome}>
                                Приятно познакомиться!<br/>
                                Перезвоним Вам совсем скоро.
                            </div>
                        </div>
                    </>
                }
            </div>

            <div className={classes.footerDown}>
                <div className={classes.email}>
                <a 
                    href="https://t.me/Ceo_agency">
                        Telegram</a> 
                </div>
                <div className={classes.footerCeo}>
                    <span>
                    <a
                        href="mailto: ceoagency@gmail.com"
                        onMouseEnter={() => setType('activeResp')}
                        onMouseLeave={() => setType('defaultResp')}
                    >ceoagency@gmail.com</a>       
                    </span>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Govacan
