import React, {useContext, useEffect} from "react"
import classes from "./menu.module.scss"
import {Link} from "react-router-dom";
import clsx from "clsx";
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext';

export function Menu(props) {
    const {setType} = useContext(CustomCursorContext)
    useEffect(()=>{
        window.scrollTo(0,0)},[])
    return (
            <div className={classes.loaderContainer}>
                <div className={classes.line}></div>
                <div className={clsx(classes.MenuContainer,"animate__animated", "animate__fadeInUpBig")}>
                    {/* <img alt="img" className={classes.logoMenu} src={logo}/> */}
                    {/* <Link to={"/"}><img alt="img" className={classes.btnClose} src={x} /></Link> */}
                    <div className={classes.leftContainer}>
                    <div className={classes.textBox}>
                            <div>г. Ярославль, ул. Флотская, 18</div>
                        <div>Работаем с 9:00 до 19:00</div>
                        </div>
                        <div className={classes.textBox}>
                            <div className="textBoxLinks">
                            <a
                                href="https://t.me/Ceo_agency"
                                className={classes.telegram}
                                onMouseEnter={() => setType('activeC')}
                                onMouseLeave={() => setType('defaultC')}    
                            >Telegram<br/></a>
                            </div>
                            <div className="textBoxLinks">
                            <a
                                href="mailto:ceoagency@gmail.com"
                                onMouseEnter={() => setType('activeC')}
                                onMouseLeave={() => setType('defaultC')}    
                            >ceoagency@gmail.com</a>
                            </div>
                        </div>
                    </div>
                    <div  className={classes.rightContainer}>
                        <Link to={"/"}
                            onClick={props.disableHeader}
                            onMouseEnter={() => setType('activeC')}
                            onMouseLeave={() => setType('defaultC')}
                        >
                            Главная
                        </Link>
                        <Link to={"/Skills"}
                            onClick={props.disableHeader}
                            onMouseEnter={() => setType('activeC')}
                            onMouseLeave={() => setType('defaultC')}
                        >
                            Услуги</Link>
                        <Link to={"/Vacancies"}
                            onClick={props.disableHeader}
                            onMouseEnter={() => setType('activeC')}
                            onMouseLeave={() => setType('defaultC')}
                        >
                            Вакансии</Link>
                    </div>
                    <div className={classes.contBtn}>
                        <Link to={"/GoClient"} onClick={props.disableHeader} className={classes.btnBuy}>Стать клиентом</Link>
                    </div>
                </div>
            </div>
    )

}