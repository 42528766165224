import React, {useContext} from "react"
import classes from "./style.module.scss"
import animateClasses from "animate.css"
import ScrollAnimation from 'react-animate-on-scroll';
import clsx from "clsx";
import { Link } from "react-router-dom";
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext';


export function SecondSlide(props) {
    const {setType} = useContext(CustomCursorContext)
    return (
        <div className={classes.SecondSlide}>
            <ScrollAnimation
                animatePreScroll={false}
                animateOnce={true}
                             animateIn='animate__animated animate__fadeInUp'
                             className={clsx(classes.container, classes.WhoContainer)}>
                <div className={classes.headerwho}><h3>Кто мы?</h3></div>
                <div className={classes.who}>
                    <div className={classes.centerLine}/>
                    Диджитал–агентство полного цикла 
                    с собственной продуктовой аналитикой, 
                    дизайном и веб–разработкой. Опытная
                    команда, которая закрывает весь спектр
                    задач для увеличения эффективности 
                    бизнеса.
                </div>
            </ScrollAnimation>
            <ScrollAnimation  animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp' className={clsx(classes.container, classes.containerHeaderDo)}>
                <div
                                 className={classes.headerDo}><h3>Что делаем?</h3></div>
                <div className={classes.whatDo}>
                    <ScrollAnimation animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp' className={classes.whatDoBlock}>
                        <div className={classes.headerGrid}>
                            Консалтинг,<br/>
                            стратегия,<br/>
                            упаковка бизнеса
                        </div>
                        <div className={classes.textGrid}>
                            Проводя тщательный анализ, мы выявляем все проблемы вашего бизнеса и готовим стратегию по их решению. Результат — ваш бизнес работает как часы и готов к масштабированию.
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp' className={classes.whatDoBlock}>
                        <div className={classes.headerGrid}>
                            Маркетинг
                            <br/>
                            и продвижение
                        </div>
                        <div className={classes.textGrid}>
                            Любой современный бизнес должен иметь репутацию. Доверьте это нам. Наша команда обладает уникальными маркетинговыми навыками, которые будут продвигать вашу компанию, привлекать новых платежеспособных клиентов и увеличат продажи.
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp' className={classes.whatDoBlock}>
                        <div className={classes.headerGrid}>
                            Продажи
                        </div>
                        <div className={classes.textGrid}>
                            Увеличение объемов продаж — это не только автоворонки, прогревы, чат-боты. Также это люди, которые взаимодействуют с клиентами. Мы создадим команду продавцов, которые удовлетворят запросы бизнеса и потребности клиентов. Внедрим системы контроля и мотивации отдела продаж.
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp' className={classes.whatDoBlock}>
                        <div className={classes.headerGrid}>
                            Брендинг<br/>
                            и фирменный<br/>
                            стиль
                        </div>
                        <div className={classes.textGrid}>
                            Уникальность вашего бизнеса. Для нас важно не просто упаковать ваш продукт, а сделать его «вкусным» и отличающимся от ваших конкурентов! У нас работают лучшие дизайнеры. Мы полны креативных идей и всегда отходим от шаблонов. 
                        </div>
                        <div className={classes.Allskills} >

                            <Link
                                onClick={() => { props.setScrollPosition(window.pageYOffset) }}
                                style={{ color: "white", textDecoration: "none" }}
                                to={"/Skills"}
                                onMouseEnter={() => setType('activeResp')}
                                onMouseLeave={() => setType('defaultResp')}
                            >
                                Подробнее о скиллах
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g id="24 / arrows / arrow-right">
                                        <path id="icon" fillRule="evenodd" clipRule="evenodd"
                                              d="M17.5858 13H3V11H17.5858L11.2929 4.70706L12.7071 3.29285L21.4142 12L12.7071 20.7071L11.2929 19.2928L17.5858 13Z"
                                              fill="white"/>
                                    </g>
                                </svg>
                            </Link>
                        </div>
                    </ScrollAnimation>
                </div>
            </ScrollAnimation>

            <div className={clsx(classes.container, classes.WhoDoContainer)}>
                <ScrollAnimation animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp'
                                 className={classes.headerWhoDo}><h3>Как делаем?</h3></ScrollAnimation>
                <div className={classes.whoDo}>

                    <ScrollAnimation animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp'
                                     className={classes.whatDoCard}>
                        <div className={classes.HeaderHowDo}>
                            <span>Аудит и аналитика</span>
                            <div className={classes.counterDo}>01</div>
                        </div>
                        <div className={classes.textCard}>
                            Проводим тщательный аудит вашей компании с бизнес-аналитиком агентства: подробно изучаем все бизнес-процессы и оптимизируем их, а также внедряем масштабные качественные и количественные исследования.

                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp'
                                     className={classes.whatDoCard}>
                        <div className={classes.HeaderHowDo}>
                            Разработка стратегии
                            <div className={classes.counterDo}>02</div>
                        </div>
                        <div className={classes.textCard}>
                            Объединеняем все данные на основании проделанной аналитики бизнеса, рынка, ЦА и на их основании разрабатываем индивидуальную маркетинговую стратегию. 
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animatePreScroll={false}  animateOnce={true} animateIn='animate__animated animate__fadeInUp'
                                     className={classes.whatDoCard}>
                        <div className={classes.HeaderHowDo}>
                            Внедрение инструментов
                            <div className={classes.counterDo}>03</div>
                        </div>
                        <div className={classes.textCard}>
                            Внедряем только актуальные для вашей компании инструменты, ориентируясь на конкретные задачи и потребности. Основываясь на своем богатом опыте, используем только проверенные кейсы, гарантирующие результат. 
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp'
                                     className={classes.whatDoCard}>
                        <div className={classes.HeaderHowDo}>
                            Результаты и отчетность
                            <div className={classes.counterDo}>04</div>
                        </div>
                        <div className={classes.textCard}>
                            Еженедельные брифинги с промежуточными отчетами, продолжаем выявлять слабые места и корректировать работу каждого этапа и отдела. По итогу каждого месяца проводим сравнительный анализ, который отражает динамику всех, даже самых незначительных этапов бизнес-процессов.
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animatePreScroll={false} animateOnce={true} animateIn='animate__animated animate__fadeInUp'
                                     className={classes.whatDoCard}>
                        <div className={classes.HeaderHowDo}>
                            Поддержка и развитие
                            <div className={classes.counterDo}>05</div>
                        </div>
                        <div className={classes.textCard}>
                            Наша задача — заставить работать ваш бизнес как часы, увеличить прибыль и масштабироваться. Мы всегда находимся в плотном взаимодействии и контролируем процесс, пока не будут достигнуты поставленые цели.
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    )
}