import React, {useEffect, useState, useContext, useRef} from "react";
import classes from "./FirstSlide.module.scss"
import "./FirstSlide.module.scss"
import "video-react/dist/video-react.css"; // import css
import clsx from "clsx";
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext';
import bg from './BG2_1.mp4';
import bg2 from './BG2_320.mp4';

export function FirstSlide() {
    const {setType} = useContext(CustomCursorContext)

    let hasRendered = useRef(false);
    useEffect(() => {
        hasRendered.current = true;
    }, [])

    return (
        <div className={classes.Content} onMouseEnter={() => setType('default')}>
                <div className={clsx(classes.videobgs, "animate__animated", "animate__fadeIn", "animate__delay-2s", "animate__faster")}>
                    
                </div>
            <div className={clsx(classes.container,classes.containerColomn)}>
                <div className={clsx(classes.videobgs, "animate__animated", "animate__fadeIn", "animate__delay-2s", "animate__faster")}>
                    <video
                        className={classes.VideoBackground2}
                        muted
                        playsInline
                        autoPlay
                        loop
                    >
                        <source 
                            src={bg2} 
                            type="video/mp4" 
                        ></source>
                    </video>
                    <video
                        className={classes.VideoBackground}
                        muted
                        playsInline
                        autoPlay
                        loop
                    >
                        <source 
                            src={bg} 
                            type="video/mp4" 
                        ></source>
                    </video>
                </div>
                <div className={classes.FirstWord}>Эффективный</div>
                <div className={classes.leftContainer}>
                    <div className={classes.SecondWord}>
                        <div className={classes.animatedWords}>
                            <div><span>маркетинг</span></div>
                            <div><span>дизайн</span></div>
                            <div><span>пиар</span></div>
                            <div><span>брендинг</span></div>
                            <div><span>SMM</span></div>
                        </div>
                    </div>
                    <div className={classes.thirdWord}>для вашего бизнеса</div>
                </div>
            </div>

            <div className={classes.footerFirstSlide}>
                <div className={classes.footerInfo2}>
                    <div style={{cursor: "pointer"}} onClick={()=> window.scrollTo({
                        top: window.innerHeight + window.pageYOffset,
                        behavior: "smooth"
                    })} className={classes.footerInfo}>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="24 / arrows / arrow-bottom">
                                <path id="icon" fill-rule="evenodd" clip-rule="evenodd"
                                      d="M11.0001 18.5858V3H13.0001V18.5858L19.293 12.2929L20.7072 13.7071L12.0001 22.4142L3.29297 13.7071L4.70718 12.2929L11.0001 18.5858Z"
                                      fill="white"/>
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}