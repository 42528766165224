import React from "react"
import classes from "./style.module.scss"
import clsx from "clsx";
import Lottie from "lottie-web";
import animation from '../../loader.json';
// "animate__animated", "animate__zoomIn","animate__delay-2s"
export function Loader() {

    let animationContainer = React.createRef()

    React.useEffect(() => {
        Lottie.loadAnimation({
            container: animationContainer.current,
            animationData: animation
        })
    },[])

    
 return (
        <div>
            <div className={classes.loaderContainer} ref={animationContainer} >
            </div> 
        </div>
 )
}