import classes from "./style.module.scss"
import React, {useContext} from "react";
import { Link } from "react-router-dom";
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext';

export function ThirdSlide(props) {
    const {setType} = useContext(CustomCursorContext)

    return (
        <div className={classes.ContainerAnimate}>
            <Link
                onClick={() => props.setScrollPosition(window.pageYOffset)} to={"/GoClient"}
                className={classes.GoBuyAnimate}
                onMouseEnter={() => setType('activeResp')}
                onMouseLeave={() => setType('defaultResp')}
            >
            <div className={classes.anim}>
                <span>Cтать клиентом
                </span>
                <svg width="49" height="37" viewBox="0 0 49 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.368164" y="22" width="7" height="41" transform="rotate(-90 0.368164 22)" fill="white"/>
                    <path
                        d="M26.0049 32.6196L42.2683 16.3562C43.4399 15.1846 45.3394 15.1846 46.511 16.3562C47.6826 17.5277 47.6826 19.4272 46.511 20.5988L30.2475 36.8623L26.0049 32.6196Z"
                        fill="white"/>
                    <path
                        d="M30.3516 0L46.615 16.2635C47.7866 17.435 47.7866 19.3345 46.615 20.5061C45.4434 21.6777 43.544 21.6777 42.3724 20.5061L26.1089 4.24264L30.3516 0Z"
                        fill="white"/>
                </svg>
            </div>
            <div className={classes.anim2}><span>Cтать клиентом</span>
                <svg width="49" height="37" viewBox="0 0 49 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.368164" y="22" width="7" height="41" transform="rotate(-90 0.368164 22)" fill="white"/>
                    <path
                        d="M26.0049 32.6196L42.2683 16.3562C43.4399 15.1846 45.3394 15.1846 46.511 16.3562C47.6826 17.5277 47.6826 19.4272 46.511 20.5988L30.2475 36.8623L26.0049 32.6196Z"
                        fill="white"/>
                    <path
                        d="M30.3516 0L46.615 16.2635C47.7866 17.435 47.7866 19.3345 46.615 20.5061C45.4434 21.6777 43.544 21.6777 42.3724 20.5061L26.1089 4.24264L30.3516 0Z"
                        fill="white"/>
                </svg>
            </div>
            <div className={classes.anim3}><span>Cтать клиентом</span>
                <svg width="49" height="37"
                     viewBox="0 0 49 37" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.368164" y="22" width="7" height="41" transform="rotate(-90 0.368164 22)" fill="white"/>
                    <path
                        d="M26.0049 32.6196L42.2683 16.3562C43.4399 15.1846 45.3394 15.1846 46.511 16.3562C47.6826 17.5277 47.6826 19.4272 46.511 20.5988L30.2475 36.8623L26.0049 32.6196Z"
                        fill="white"/>
                    <path
                        d="M30.3516 0L46.615 16.2635C47.7866 17.435 47.7866 19.3345 46.615 20.5061C45.4434 21.6777 43.544 21.6777 42.3724 20.5061L26.1089 4.24264L30.3516 0Z"
                        fill="white"/>
                </svg>
            </div>
            <div className={classes.anim4}><span>Cтать клиентом</span>
                <svg width="49" height="37"
                     viewBox="0 0 49 37" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.368164" y="22" width="7" height="41" transform="rotate(-90 0.368164 22)" fill="white"/>
                    <path
                        d="M26.0049 32.6196L42.2683 16.3562C43.4399 15.1846 45.3394 15.1846 46.511 16.3562C47.6826 17.5277 47.6826 19.4272 46.511 20.5988L30.2475 36.8623L26.0049 32.6196Z"
                        fill="white"/>
                    <path
                        d="M30.3516 0L46.615 16.2635C47.7866 17.435 47.7866 19.3345 46.615 20.5061C45.4434 21.6777 43.544 21.6777 42.3724 20.5061L26.1089 4.24264L30.3516 0Z"
                        fill="white"/>
                </svg>
            </div>
            <div className={classes.anim5}><span>Cтать клиентом</span>
                <svg width="49" height="37"
                     viewBox="0 0 49 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.368164" y="22" width="7" height="41" transform="rotate(-90 0.368164 22)" fill="white"/>
                    <path
                        d="M26.0049 32.6196L42.2683 16.3562C43.4399 15.1846 45.3394 15.1846 46.511 16.3562C47.6826 17.5277 47.6826 19.4272 46.511 20.5988L30.2475 36.8623L26.0049 32.6196Z"
                        fill="white"/>
                    <path
                        d="M30.3516 0L46.615 16.2635C47.7866 17.435 47.7866 19.3345 46.615 20.5061C45.4434 21.6777 43.544 21.6777 42.3724 20.5061L26.1089 4.24264L30.3516 0Z"
                        fill="white"/>
                </svg>
            </div>

        </Link>
        </div>
    )
}