import React, {useContext, useRef, useEffect} from 'react'
import classes from "./header.module.scss"
import {Link} from "react-router-dom";
import { ReactComponent as MainLogo } from "./../../image/logo.svg";
import { ReactComponent as MainLogoText } from "./../../image/textLogo.svg";
import clsx from "clsx";
import CustomCursorContext from '../CustomCursor/context/CustomCursorContext';

export function Header(props) {
    const {setType} = useContext(CustomCursorContext)
    const [headerBl, setHeaderBl] = React.useState(false)

    let hasRendered = useRef(false);
    useEffect(() => {
        hasRendered.current = true;
    }, [])

    const changeBackground = () => {
        if ( window.scrollY >= 0) {
            setHeaderBl(true)
        }
        else {
            setHeaderBl(false)
        }
    }

    window.addEventListener('scroll', changeBackground)



    return (
            <div
                className= {headerBl 
                    ? clsx(classes.headerBl, "animate__animated", "animate__fadeIn", "animate__delay-1s")
                    : clsx(classes.headerBlChill, "animate__animated", "animate__fadeIn")} 
                style={props.headerOn ? 
                    {zIndex: "999"}
                    :
                    {zIndex: "0"}}
                onMouseEnter={() => setType('default')}
            >
                <Link onClick={()=>props.setScrollPosition(0)} 
                style={{textDecoration: "none"}} to={"/"}>
                    <MainLogo className={clsx(classes.svgLogo, "animate__animated", "animate__fadeIn")
                }
                />
                    <MainLogoText className={hasRendered.current 
                        ? clsx(classes.svgLogo, "animate__animated", "animate__fadeIn", "animate__delay-1s")
                        : clsx(classes.svgLogo, "animate__animated", "animate__fadeIn")}/>
                </Link>
                <div className={hasRendered.current 
                ? clsx(classes.line, "animate__animated", "animate__fadeIn", "animate__faster", "animate__delay-1s")
                : clsx(classes.line, "animate__animated", "animate__fadeIn")}/>
                <div className={hasRendered.current 
                ? clsx(classes.TextHeader, "animate__animated", "animate__fadeIn", "animate__faster", "animate__delay-1s")
                : clsx(classes.TextHeader, "animate__animated", "animate__fadeIn")}>
                    <div className={classes.TextHeaderFirst} >
                        <span className={classes.FirstName}>Диджитал–агентство</span>
                        <span className={classes.SecondName}><Link
                            to={"/GoClient"}
                            onMouseEnter={() => setType('active')}
                            onMouseLeave={() => setType('default')}
                        >Стать клиентом</Link></span>
                    </div>
                    <Link
                        to={!props.state ? "/Menu" : "/"}
                        className={hasRendered.current 
                            ? clsx(classes.navicon4, props.state ? classes.open : "", "animate__animated", "animate__fadeIn", "animate__faster", "animate__delay-1s")
                            : clsx(classes.navicon4, props.state ? classes.open : "", "animate__animated", "animate__fadeIn", "animate__repeat-1")}
                        onMouseEnter={() => setType('active')}
                        onMouseLeave={() => setType('default')}
                    >   
                        <span className={classes.span1}></span>
                        <span className={classes.span2}></span>
                    </Link>
                </div>
            </div>
    )
}
