import React, {useState} from 'react'
import CustomCursorContext, { CursorLookType } from './CustomCursorContext'

interface Props {
    children: JSX.Element;
} 

export default function CustomCursorManager({ children }: Props) {
    const [type, setType] = useState<CursorLookType>('default')

    return (
        <CustomCursorContext.Provider value={{type, setType}}>
            {children}
        </CustomCursorContext.Provider>
    )
}
